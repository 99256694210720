<template>
  <div class="agreement customer-agreement">
    <item-title :title="{name: '明亚自媒体', en_1: 'SOCIAL', en_2: 'MEDIA'}" />
    <div class="list">
      <transition-group tag="div" name="fadeIn" class="listGroup">
        <div class="li" v-for="(item) in pageData" :key="item.id">
          <div>
            <img :src="item.d2_code" alt="">
          </div>
          <div class="name">{{item.name}}</div>
          <div class="splitLine"></div>
          <div class="discribe">{{item.discribe}}</div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import {
  getSelfMediaList
} from '@/api/contactUs.js'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      pageData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取页面数据
    async getData() {
      const res = await getSelfMediaList()
      this.pageData = res.list
      this.$emit('loading', false)
    },
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
.list {
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.6rem;

  .listGroup {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: left;
    margin-top: 1.35rem;
    margin-left: 0.25rem;
  }

  .li {
    width: 2.47rem;
    height: 3.25rem;
    display: block;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.67rem;
    margin-right: 1.3rem;
    transition: all 0.5s ease 0s;
    &:hover {
      transform: translateY(-15px);
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    img {
      width: 100%;
      display: inline-block;
    }

    .name {
      height: 0.24rem;
      line-height: 0.24rem;
      font-size: 0.24rem;
      color: #333333;
    }

    .discribe {
      height: 0.24rem;
      line-height: 0.24rem;
      color: #888888;
      font-size: 0.16rem;
    }
    .splitLine {
      width: 0.3rem;
      height: 0.03rem;
      margin: 0.15rem auto 0.19rem;
      background-color: #fe6917;
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .list {
    .listGroup {
      margin-left: 0;
      margin-top: 0.67rem;
    }
    .li {
      width: 2.4rem;
      margin-left: auto;
      margin-right: auto;
      &:nth-of-type(3n) {
        margin-right: auto;
      }
    }
  }
}
</style>
